.svg-button-line {
	background: url("svg/sprite.css-7c84e484.svg") 0 0 no-repeat;
}

.svg-button-line-dims {
	width: 49px;
	height: 70px;
}

.svg-logo {
	background: url("svg/sprite.css-7c84e484.svg") 74.24242424242425% 0 no-repeat;
}

.svg-logo-dims {
	width: 36px;
	height: 48px;
}

.svg-logo-telegram {
	background: url("svg/sprite.css-7c84e484.svg") 80% 92.3076923076923% no-repeat;
}

.svg-logo-telegram-dims {
	width: 17px;
	height: 18px;
}

.svg-logo-viber {
	background: url("svg/sprite.css-7c84e484.svg") 100% 0 no-repeat;
}

.svg-logo-viber-dims {
	width: 17px;
	height: 18px;
}

.svg-phone {
	background: url("svg/sprite.css-7c84e484.svg") 59.036144578313255% 94.11764705882354% no-repeat;
}

.svg-phone-dims {
	width: 19px;
	height: 19px;
}

