.product{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-top: 181px;
	padding-bottom: 76px;
	position: relative;
	@include lg{
		padding-top: 40px;
		padding-bottom: 60px;
	}
	@include sm{
		padding-top: 30px;
		padding-bottom: 40px;
	}
	@include xs{
		padding-top: 15px;
		padding-bottom: 25px;
	}
	.advantages{
		display: flex;
		align-items: center;
	}
	.advantage{
		margin: 0 17px;
	}
	.content{
		display: flex;
		flex-wrap: wrap;
		@include sm{
			flex-direction: column;
		}
	}
	.left{
		width: 50%;
		padding-top: 57px;
		padding-left: 17px;
		padding-right: 17px;
		@include lg{
			padding-top: 40px;
		}
		@include sm{
			width: 100%;
			padding-top: 20px;
			padding-left: 0;
			padding-right: 0;
			&:first-child{
				display: none;
			}
		}
	}
	.right{
		width: 50%;
		padding-left: 26px;
		@include sm{
			width: 100%;
			padding-left: 0;
		}
	}
	.image{
		margin-top: 12px;
		width: 520px;
		box-shadow: 10px 2px 21px 1px rgba(79, 71, 73, 0.64);
		font-size: 0;
		position: relative;
		max-width: 100%;
		&.karaoke{
			&:after{
				content:''; 
				width: 187px;
				height: 295px;
				background-image: url(../img/old/karaoke.png);
				background-repeat: no-repeat;
				background-size: 187px 295px;
				background-position: center center;
				position: absolute;
				right: -70px;
				bottom: -12px;
				@include lg{
					width: 150px;
					height: 240px;
					background-size: 150px 234px;
					right: -55px;
				}
				@include md{
					width: 100px;
					height: 157px;
					background-size: 100px 157px;
					right: -45px;
				}
				@include sm{
					width: 80px;
					height: 110px;
					background-size: 70px 110px;
					right: -25px;
				}
			}
		}
		picture{
			width: 100%;
		}
		img{
			width: 100%;
			border-radius: 3px;
		}
	}
	.icon{
		width: 67px;
		// margin-right: 6px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-shrink: 0;
		position: absolute;
		left: 0;
		top: 12px;
		transform: translateY(-50%);
		&.top_marg{
			top: 50%;
		}
		@include lg{
			top: 11px;
		}
		@include md{
			width: 60px;
			top: 8px;
		}
		img{
			max-width: 100%;
			max-height: 38px;
		}
		&-widthed{
			img{
				max-width: 45px;
			}
		}
	}
	.infos{
		margin-top: 59px;
		@include lg{
			margin-top: 40px;
		}
		@include xs{
			margin-top: 10px;
		}
	}
	.info{
		display: flex;
		margin-bottom: 30px;
		position: relative;
	}
	.text{
		color: #333333;
		font-size: 23px;
		font-weight: 400;
		line-height: 1.1;
		padding-left: 77px;
		@include lg{
			font-size: 20px;
		}
		@include md{
			font-size: 16px;
			padding-left: 65px;
		}
	}
	.price{
		display: flex;
		margin-top: 68px;
		align-items: center;
		// justify-content: center;
		@include lg{
			margin-top: 50px;
		}
		@include md{
			margin-top: 40px;
		}
		@include sm{
			justify-content: center;
		}
		.old{
			color: #000;
			line-height: 1.11;
			text-align: center;
			margin-top: 4px;
			font-size: 40px;
			position: relative;
			margin-right: 40px;
			margin-left: 6px;
			@include lg{
				font-size: 28px;
				margin-left: 0;
			}
			@include md{
				font-size: 25px;
				margin-right: 20px;
			}
			@include sm{
				font-size: 17px;
			}
			.value{
				font-family: $font0;
				font-weight: 300;
				font-size: 42px;
				margin-right: 1px;
				@include lg{
					font-size: 32px;
				}
				@include md{
					font-size: 28px;
				}
				@include sm{
					font-size: 20px;
				}
			}
			&:after{
				content: '';
				position: absolute;
				height: 3px;
				width: 110%;
				background: #ff7d00;
				left: -5%;
				top: 50%;
				transform: rotate(-3deg) translateY(-50%);
			}
		}
		.new{
			color: #ffffff;
			position: relative;
			text-align: center;
			padding: 1.8% 6% 1.7% 7%;
			min-width: 167px;
			margin-right: 41px;
			@include lg{
				margin-right: 0;
			}
			@include xs{
				padding: 1.8% 6% 1.7% 7%;
				min-width: 150px;
			}
			&::after{
				content: attr(data-description);
				color: #ff7d00;
				font-size: 43px;
				font-weight: 900;
				line-height: 1.09;
				text-transform: uppercase;
				position: absolute;
				top: 0;
				left: 50%;
				margin-top: -51px;
				transform: translateX(-50%);
				margin-left: 14px;
				@include lg{
					font-size: 30px;
					margin-top: -34px;
				}
				@include md{
					font-size: 26px;
				}
				@include xs{
					font-size: 23px;
					margin-top: -26px;
					margin-left: 4px;
				}
			}
			&::before{
				content: '';
				background-color: #ff7d00;
				position: absolute;
				left: 0;
				width: 100%;
				top: 0;
				height: 100%;
				transform: skewX(-18deg);
			}
			.inner{
				position: relative;
				z-index: 1;
				white-space: nowrap;
				font-weight: 300;
				font-size: 51px;
				font-family: $font1;
				@include lg{
					font-size: 40px;
				}
				@include md{
					font-size: 30px;
				}
				@include xs{
					font-size: 22px;
				}
			}
			.value{
				font-family: $font0;
				font-weight: 700;
				font-size: 58px;
				line-height: 1.11;	
				margin-right: 19px;
				@include lg{
					font-size: 45px;
				}
				@include md{
					font-size: 40px;
				}
				@include xs{
					font-size: 25px;
				}
			}
		}
	}
	.button-wrap{
		margin-top: 22px;
		display: flex;
		@include md{
			flex-direction: column-reverse;
		}
	}
	.button-buy{
		margin: auto;
		position: relative;
		left: -27px;
		@include md{
			left: 0;
			margin-bottom: 20px;
		}
	}
	.button-scroll{
		margin: auto;
		position: absolute;
		bottom: 26px;
		left: 50%;
		transform: translateX(-50%);
	}
	&-1{
		background-image: url(../img/old/bg2.jpg);
	}
	&-2{
		background-image: url(../img/old/bg3.jpg);
		padding-top: 116px;
		padding-bottom: 139px;
		@include lg{
			padding-top: 40px;
			padding-bottom: 60px;
		}
		.price{
			justify-content: center;
			margin-top: 61px;
		}
		
	}
	&-3{
		background-image: url(../img/old/bg4.jpg);
		padding-top: 122px;
		padding-bottom: 93px;
		@include lg{
			padding-top: 40px;
			padding-bottom: 60px;
		}
	}
	&-4{
		background-image: url(../img/old/bg5.jpg);
		padding-top: 118px;
		padding-bottom: 93px;
		@include lg{
			padding-top: 40px;
			padding-bottom: 60px;
		}
	}
}