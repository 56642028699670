.compare{
	background-image: url(../img/old/bg6.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-top: 68px;
	padding-bottom: 62px;
	@include md{
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.container{
		padding-left: 0;
		padding-right: 0;
		@include md{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	table, th, tr, td{
		border: none;
		padding: 0;
		border-spacing: 0;
	}
	.table{
		width: 100%;
		background: #fff;
		border: 1px solid rgba(#888, .3);
		@include sm{
			display: none;
		}
		td{
			border-bottom: 1px solid rgba(#888, .3);
			color: #333333;
			font-size: 17px;
			font-weight: 400;
			line-height: 1.2;
			padding: 0 26px 0;
			height: 57px;
			@include md{
				font-size: 14px;
			}
			&:not(:last-child){
				border-right: 1px solid rgba(#888, .3);
			}
			&:not(:first-child){
				text-align: center;
				width: 193px;
			}
			&:nth-of-type(4){
				width: 218px;
			}
		}
		thead{
			color: #b7274c;
			font-size: 23px;
			font-weight: 900;
			line-height: 1.1;
			text-transform: uppercase;
			text-align: center;
			@include md{
				font-size: 20px;
			}
			th{
				padding-top: 15px;
				padding-bottom: 8px;
				&:not(:last-child){
					border-right: 1px solid rgba(#888, .3);
				}
			}
		}
		.title{
			background-color: #b7274c;
			td{
				padding: 0 26px;
				color: #ffffff;
				font-size: 23px;
				font-weight: 900;
				line-height: 1.52;
				text-transform: uppercase;
				height: 46px;
				@include md{
					font-size: 20px;
				}
			}
		}
		.circle{
			width: 13px;
			height: 13px;
			background-color: #b7274c;
			display: inline-block;
			border-radius: 50%;
			@include md{
				width: 10px;
				height: 10px;
			}
		}
	}
	.slider{
		display: none;
		position: relative;
		@include sm{
			display: block;
		}
		&-control{
			width: 30px;
			height: 21px;
			position: absolute;
			left: 0;
			top: 14px;
			border: none;
			background-repeat: no-repeat;
			background-size: 30px 21px;
			background: transparent;
			z-index: 3;
			cursor: pointer;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30.5px' height='21.5px'%3E %3Cpath fill-rule='evenodd' stroke='rgb(184, 39, 78)' stroke-width='5px' stroke-linecap='round' stroke-linejoin='round' fill='none' d='M24.801,7.723 L18.004,14.775 C15.796,17.066 12.216,17.066 10.008,14.775 L3.212,7.723 '/%3E %3C/svg%3E");
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			&-prev{
				transform: rotate(90deg);
			}
			&-next{
				transform: rotate(-90deg);
				right: 0;
				left: auto;
			}
			&.swiper-button-disabled{
				opacity: .3;
			}
		}
	}
	.table-mobile{
		width: 100%;
		td{
			border-bottom: 1px solid rgba(#888, .3);
			color: #333333;
			font-weight: 400;
			line-height: 1.2;
			padding: 0 14px 0;
			height: 57px;
			font-size: 14px;
			&:not(:last-child){
				border-right: 1px solid rgba(#888, .3);
			}
			&:not(:first-child){
				text-align: center;
				width: 193px;
			}
			&:nth-of-type(4){
				width: 218px;
			}
		}
		thead{
			color: #b7274c;
			font-weight: 900;
			line-height: 1.1;
			text-transform: uppercase;
			text-align: center;
			font-size: 20px;
			th{
				padding-top: 15px;
				padding-bottom: 25px;
			}
		}
		tbody{
			border: 1px solid rgba(#888, .3);
			background: #fff;
		}
		.title{
			background-color: #b7274c;
			td{
				padding: 4px 14px;
				color: #ffffff;
				line-height: 1.1;
				text-transform: uppercase;
				// height: 49px;
				font-size: 16px;
				font-weight: 700;
			}
		}
		.circle{
			width: 10px;
			height: 10px;
			background-color: #b7274c;
			display: inline-block;
			border-radius: 50%;
		}
	}

}