.footer{
	&_real{
		background-color: #ff7d00;
		height: 84px;
		@include md{
			height: 65px;
		}
		@include xs{
			height: 48px;
		}
		.container{
			display: flex;
			justify-content: center;
			position: relative;
			align-items: center;
			height: 100%;
			@include sm{
				align-items: center;
				justify-content: space-between;
			}
			&:after{
				content: '';
				display: block;
				width: 1px;
				@include sm{
					content: none;
				}
			}
		}
		.logo{
			position: absolute;
			left: 0;
			display: flex;
			flex-direction: column;
			top: 50%;
			transform: translateY(-50%);
			margin-left: -6px;
			margin-top: 0px;
			@include md{
				margin-left: 15px;
				width: 190px;
				img{
					width: 100%;
				}
			}
			@include sm{
				position: static;
				margin-top: 0;
				margin-left: 0;
				transform: none;
			}
			@include xs{
				width: 140px;
			}
			.text{
				color: #ffffff;
				font-family: $font2;
				font-size: 21px;
				font-weight: 400;
				line-height: 0.8;
				text-align: center;
				margin-top: 7px;
				@include md{
					font-size: 20px;
					margin-top: 6px;
				}
				@include xs{
					font-size: 14px;
					// margin-top: 8px;
				}
			}
		}
		.logo_dns{
			font-size: 0;
			@include md{
				width: 250px;
				img{
					width: 100%;
				}
			}
			@include xs{
				width: 160px;
			}
		}
	}
	&_text{
		.text{
			padding-top: 19px;
			padding-bottom: 21px;
			padding-right: 27px;
			padding-left: 27px;
			color: #878787;
			font-family: $font1;
			font-size: 17px;
			font-weight: 400;
			line-height: 1.3;
			text-transform: uppercase;
			text-align: center;
			@include xs{
				padding-left: 0;
				padding-right: 0;
				font-size: 16px;
			}
			a{
				color: #227cbb;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}